body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

tr:hover{
  background-color: rgba(159, 217, 255, 0.363) !important;
}
tr:hover td{
  background-color: rgba(159, 217, 255, 0.363) !important;
}

.button-link{
  width:95%; 
  display:flex;
  justify-content:space-between;
  align-items:center;
  border:none; 
  background-color:transparent; 
  cursor:pointer;
  color:black;
}

.link{
  background-color: transparent;
  border:none; 
  border-radius: 5px;
  border-bottom:1px solid #e1e1e1; 
}
.link:hover{
  background-color: rgba(241, 241, 241, 0.877);
}